import { styled, Typography as MuiTypography } from '@mui/material';
import { ReactNode } from 'react';

interface AdditionalProps {
	maxLines?: number;
	component?: ReactNode;
}

/* 
	A MUI Typography component with ability to select maximum number of lines
	after which the text will overflow into an ellipsis
*/

const Typography = styled(MuiTypography, {
	shouldForwardProp: (prop: string) => !['maxLines'].includes(prop),
})<AdditionalProps>(({ theme, maxLines }) => ({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	WebkitLineClamp: maxLines,
	lineClamp: maxLines,
	WebkitBoxOrient: 'vertical',
}));

export default Typography;
