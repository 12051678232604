import { Add } from '@mui/icons-material';
import { Box, Paper, styled } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useQuery } from 'react-query';
import Header from '../components/Header';
import withAuth from '../components/withAuth';
import { SubscriptionPlansService } from '../services/SubscriptionPlansService';

const VersionsPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',

  '.body': {
    margin: theme.spacing(3, 2),
    flex: '1 1 auto',
  },
}));

function SubscriptionPlansPage() {
  const { data: plansResult, isLoading } = useQuery(
    'getSubscriptionPlans',
    () => SubscriptionPlansService.getSubscriptionPlans()
  );

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80,
      flex: 0,
      filterable: false,
      hideable: false,
    },
    {
      field: 'name',
      headerName: 'Name',
      minWidth: 180,
      flex: 1,
      filterable: false,
      hideable: false,
    },
    {
      field: 'period',
      headerName: 'Period',
      minWidth: 180,
      flex: 1,
      filterable: false,
      hideable: false,
    },
  ];

  return (
    <VersionsPageContainer>
      <Header
        title="Subscriptions"
        button={{
          text: 'Add Subscription',
          startIcon: <Add />,
          onClick: () => {},
        }}
      />
      {/* 
			<EditSubscriptionPlanDialog
				isOpen={true}
				onClose={() => {}}
				isUpdate={false}
			/> */}

      <Paper elevation={0} className="body">
        <DataGrid
          rows={plansResult?.objects ?? []}
          loading={isLoading}
          columns={columns}
          disableSelectionOnClick
          disableColumnMenu
          disableColumnSelector
          sortModel={[{ field: 'id', sort: 'asc' }]}
        />
      </Paper>
    </VersionsPageContainer>
  );
}

export default withAuth(SubscriptionPlansPage, true, false);
