import { Alert, AlertColor, Snackbar } from '@mui/material';
import { createContext, ReactNode, useState } from 'react';

interface ContextProps {
	toast: (text: string, severity?: AlertColor) => void;
}

const initialState: ContextProps = {
	toast: () => null,
};

export const ToastContext = createContext<ContextProps>(initialState);

export function ToastContextProvider({ children }: { children: ReactNode }) {
	const [isOpen, setIsOpen] = useState(false);
	const [text, setText] = useState('');
	const [severity, setSeverity] = useState<AlertColor>('success');

	const onClose = () => {
		setIsOpen(false);
	};

	const contextValue: ContextProps = {
		toast(text: string, severity = 'error') {
			setText(text);
			setSeverity(severity);
			setIsOpen(true);
		},
	};

	return (
		<ToastContext.Provider value={contextValue}>
			<Snackbar
				open={isOpen}
				autoHideDuration={6000}
				onClose={onClose}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			>
				<Alert
					onClose={onClose}
					severity={severity}
					sx={{ width: '100%' }}
					variant="filled"
				>
					{text}
				</Alert>
			</Snackbar>
			{children}
		</ToastContext.Provider>
	);
}
