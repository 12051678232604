import React from 'react';
import { Alert, Snackbar } from '@mui/material';
import { createContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactNodeProps } from '../types';
import { StoreState } from '../store';
import { hideError } from '../store/appSlice';

export interface AppContextState {}

const initialState: AppContextState = {};

// A global app context for showing errors for the user
export const AppContext = createContext(initialState);

export function AppContextProvider({ children }: ReactNodeProps) {
	const errorData = useSelector((state: StoreState) => state.app.errorData);
	const dispatch = useDispatch();

	const onSnackbarClose = () => {
		dispatch(hideError());
	};

	const contextValue: AppContextState = {};

	return (
		<AppContext.Provider value={contextValue}>
			<Snackbar
				open={errorData.display}
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				onClose={onSnackbarClose}
				autoHideDuration={6000}
			>
				<Alert
					variant="filled"
					severity="error"
					onClose={onSnackbarClose}
					closeText={'Close'}
					sx={{
						'.MuiIconButton-root': {
							minWidth: 'initial',
							minHeight: 'initial',
						},
					}}
				>
					{errorData.message}
				</Alert>
			</Snackbar>
			{children}
		</AppContext.Provider>
	);
}
