import { GlobalStyles } from '@mui/material';

export default function AppStyles() {
	return (
		<GlobalStyles
			styles={(theme) => ({
				'*': {
					boxSizing: 'border-box',
				},
				':root': {
					fontSize: 16,
				},
				body: {
					backgroundColor: theme.palette.background.default,
					color: theme.palette.text.primary,
					overflowX: 'hidden',
				},
			})}
		/>
	);
}
