import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Paper, styled } from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridColumns,
	GridSortItem,
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import DeleteConfirmDialog from '../components/DeleteConfirmDialog';
import EditVersionDialog from '../components/forms/EditVersionDialog';
import Header from '../components/Header';
import withAuth from '../components/withAuth';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from '../constants';
import useToast from '../hooks/useToast';
import ApiService from '../services/ApiService';
import { useAppDispatch } from '../store';
import { setIsAddVersionDialogOpen } from '../store/appSlice';
import { PostVersion, Version } from '../types';

const VersionsPageContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',

	'.body': {
		margin: theme.spacing(3, 2),
		flex: '1 1 auto',
	},
}));

function VersionsPage() {
	const dispatch = useAppDispatch();
	const toast = useToast();

	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
	const [activeVersion, setActiveVersion] = useState<Version | undefined>();
	const {
		data: versions,
		isLoading,
		refetch,
	} = useQuery('getVersions', () => ApiService.getVersions());

	const deleteVersionMutation = useMutation(
		'deleteVersion',
		(id: number) => ApiService.deleteVersion(id),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess: () => {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				refetch();
			},
		}
	);

	const onAddVersionClick = () => {
		dispatch(setIsAddVersionDialogOpen(true));
	};

	const onEditVersionClick = (version: Version) => () => {
		setActiveVersion(version);
		dispatch(setIsAddVersionDialogOpen(true));
	};

	const onDeleteVersionClick = (version: Version) => () => {
		setIsDeleteConfirmOpen(true);
		setActiveVersion(version);
	};

	const columns: GridColDef[] = [
		{
			field: 'id',
			headerName: 'ID',
			width: 80,
			flex: 0,
			filterable: false,
			hideable: false,
		},
		{
			field: 'androidMinVersion',
			headerName: 'Android Min Version',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'androidCurVersion',
			headerName: 'Android Cur Version',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'iosMinVersion',
			headerName: 'IOS Min Version',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'iosCurVersion',
			headerName: 'IOS Cur Version',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'action',
			headerName: '',
			renderCell: (params) => {
				const value: Version = params.row;

				return (
					<>
						<IconButton onClick={onEditVersionClick(value)}>
							<Edit />
						</IconButton>
						<IconButton color="error" onClick={onDeleteVersionClick(value)}>
							<Delete />
						</IconButton>
					</>
				);
			},
			flex: 1,
			minWidth: 100,
			maxWidth: 100,
			hideable: false,
			sortable: false,
		},
	];

	const versionDefaultValues = useMemo<Partial<PostVersion> | undefined>(() => {
		if (!activeVersion) {
			return;
		}

		return {
			...activeVersion,
		} as Partial<Version>;
	}, [activeVersion]);

	const creationDefaultValues = useMemo<
		Partial<PostVersion> | undefined
	>(() => {
		if (!versions) {
			return;
		}

		return {
			...versions[versions.length - 1],
		} as Partial<Version>;
	}, [versions]);

	return (
		<VersionsPageContainer>
			<EditVersionDialog
				onClose={() => {
					setActiveVersion(undefined);
					dispatch(setIsAddVersionDialogOpen(false));
				}}
				version={activeVersion}
				defaultValues={versionDefaultValues}
				creationDefaultValues={creationDefaultValues}
			/>

			<DeleteConfirmDialog
				isOpen={isDeleteConfirmOpen}
				title={`Are you sure you want to delete the version?`}
				onMutation={async () =>
					deleteVersionMutation.mutate(activeVersion?.id ?? 0)
				}
				onCancel={() => {
					setIsDeleteConfirmOpen(false);
				}}
				onSuccess={() => {
					setIsDeleteConfirmOpen(false);
					setActiveVersion(undefined);
					refetch();
				}}
			/>
			<Header
				title="Versions"
				button={{
					text: 'Add Version',
					startIcon: <Add />,
					onClick: onAddVersionClick,
				}}
			/>

			<Paper elevation={0} className="body">
				<DataGrid
					rows={versions ?? []}
					loading={isLoading}
					columns={columns}
					disableSelectionOnClick
					disableColumnMenu
					disableColumnSelector
					sx={(theme) => ({
						'.MuiDataGrid-row:first-child': {
							background: theme.palette.action.selected,
						},
					})}
					sortModel={[{ field: 'id', sort: 'desc' }]}
				/>
			</Paper>
		</VersionsPageContainer>
	);
}

export default withAuth(VersionsPage, true, false);
