import { Menu } from '@mui/icons-material';
import {
	AppBar,
	Box,
	Button,
	IconButton,
	Stack,
	Toolbar,
	Typography,
} from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { setIsSideMenuOpen } from '../store/appSlice';

interface HeaderProps {
	title: string;
	button?: {
		onClick: React.MouseEventHandler;
		startIcon: React.ReactNode;
		text: string;
	};
}

export default function Header({ title, button }: HeaderProps) {
	const dispatch = useDispatch();

	const onSideMenuOpen = () => {
		dispatch(setIsSideMenuOpen(true));
	};

	return (
		<AppBar
			elevation={0}
			position="sticky"
			sx={{ flex: '0 0 auto', height: 'fit-content' }}
		>
			<Toolbar
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					alignItems: 'center',
					px: 2,
				}}
			>
				<Stack direction="row" alignItems="center">
					<IconButton
						size="large"
						edge="start"
						sx={{ display: { xs: 'inherit', md: 'none' } }}
						onClick={onSideMenuOpen}
					>
						<Menu />
					</IconButton>
					<Typography variant="h6">{title}</Typography>
				</Stack>

				{button && (
					<Button
						variant="contained"
						onClick={button.onClick}
						startIcon={button.startIcon}
					>
						{button.text}
					</Button>
				)}
			</Toolbar>
		</AppBar>
	);
}
