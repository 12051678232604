import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	PaperProps,
	TextField,
} from '@mui/material';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import { useSelector } from 'react-redux';
import {
	DEFAULT_ERROR_MESSAGE,
	DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import useToast from '../../hooks/useToast';
import ApiService from '../../services/ApiService';
import { StoreState, useAppDispatch } from '../../store';
import { setIsAddVersionDialogOpen } from '../../store/appSlice';
import { PostVersion, Version } from '../../types';
import Typography from '../material/Typography';

interface PaperPropsType extends PaperProps {
	component: 'form';
}

interface DialogProps {
	onClose: () => void;
	version?: Version;
	defaultValues?: Partial<PostVersion>;
	creationDefaultValues?: Partial<PostVersion>;
}

export default function EditVersionDialog({
	onClose,
	version,
	defaultValues,
	creationDefaultValues,
}: DialogProps) {
	const toast = useToast();
	const queryClient = useQueryClient();
	const isUpdate = defaultValues !== undefined;
	const isOpen = useSelector(
		(state: StoreState) => state.app.isAddVersionDialogOpen
	);
	const dispatch = useAppDispatch();
	const saveArtistMutation = useMutation(
		(data: PostVersion) =>
			isUpdate
				? ApiService.updateVersion(version?.id ?? 0, data)
				: ApiService.createVersion(data),
		{
			onError(error, variables, context) {
				toast(DEFAULT_ERROR_MESSAGE);
			},
			onSuccess: () => {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');
				onDialogClose();
				queryClient.refetchQueries('getVersions');
			},
		}
	);
	const { isLoading } = saveArtistMutation;

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
		reset,
		watch,
	} = useForm<PostVersion>();

	const onDialogClose = () => {
		if (isLoading) {
			return;
		}

		dispatch(setIsAddVersionDialogOpen(false));
		onClose();
		reset({
			androidMinVersion: undefined,
			androidCurVersion: undefined,
			iosMinVersion: undefined,
			iosCurVersion: undefined,
		});
	};

	const onSubmit = (data: PostVersion) => {
		if (isLoading) {
			return;
		}

		// const data: ArtistFormProps = dataAny;

		// const mutationData: ArtistCreate = {
		// 	userRegistrationDto: {
		// 		email: data.email,
		// 		fullName: data.name,
		// 		password: '123456',
		// 		phoneNumber: '+77777777777',
		// 	},
		// 	avatar: data.avatar,
		// };

		saveArtistMutation.mutate(data);
	};

	const DialogPaperProps: PaperPropsType = {
		component: 'form',
		onSubmit: handleSubmit(onSubmit),
	};

	useEffect(() => {
		if (!defaultValues || !isOpen) {
			reset(creationDefaultValues);
			return;
		}

		reset(defaultValues);
	}, [defaultValues, creationDefaultValues, isOpen]);

	return (
		<Dialog
			open={isOpen}
			onClose={onDialogClose}
			fullWidth
			maxWidth="md"
			PaperProps={DialogPaperProps}
		>
			<DialogTitle
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				{isUpdate ? 'Edit Version' : 'Add Version'}
				<IconButton edge="end" onClick={onDialogClose} disabled={isLoading}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box pt={1}>
					<Grid container spacing={2}>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">Android</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									disabled={isLoading}
									fullWidth
									label="Minimum Version"
									inputProps={{
										...register('androidMinVersion', {
											required: 'Please, enter Android minimum version',
										}),
									}}
									error={Boolean(errors.androidMinVersion)}
									helperText={errors.androidMinVersion?.message}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									disabled={isLoading}
									fullWidth
									label="Current Version"
									inputProps={{
										...register('androidCurVersion', {
											required: 'Please, enter Android current version',
										}),
									}}
									error={Boolean(errors.androidMinVersion)}
									helperText={errors.androidMinVersion?.message}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Divider />
						</Grid>
						<Grid item container xs={12} spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h6">IOS</Typography>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									disabled={isLoading}
									fullWidth
									label="Minimum Version"
									inputProps={{
										...register('iosMinVersion', {
											required: 'Please, enter IOS minimum version',
										}),
									}}
									error={Boolean(errors.androidMinVersion)}
									helperText={errors.androidMinVersion?.message}
								/>
							</Grid>
							<Grid item xs={12} sm={6}>
								<TextField
									disabled={isLoading}
									fullWidth
									label="Current Version"
									inputProps={{
										...register('iosCurVersion', {
											required: 'Please, enter IOS current version',
										}),
									}}
									error={Boolean(errors.androidMinVersion)}
									helperText={errors.androidMinVersion?.message}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Grid container columnSpacing={2} rowSpacing={1} ml={0} mb={1}>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
						lg={2}
						ml="auto"
						order={{ xs: 2, sm: 1 }}
					>
						<Button
							fullWidth
							size="large"
							onClick={onDialogClose}
							disabled={isLoading}
							color="inherit"
						>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, sm: 2 }}>
						<LoadingButton
							variant="contained"
							fullWidth
							size="large"
							type="submit"
							loading={isLoading}
						>
							{isUpdate ? 'Save' : 'Create'}
						</LoadingButton>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
