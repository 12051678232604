import {
  Close,
  Filter,
  Filter1,
  Logout,
  Notifications,
  Person,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemText,
  Stack,
  SwipeableDrawer,
  Toolbar,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { SIDEBAR_WIDTH } from '../constants';
import AuthService from '../services/AuthService';
import { StoreState } from '../store';
import { setIsSideMenuOpen } from '../store/appSlice';
import RouterLink from './RouterLink';

const LINKS = [
  {
    icon: <Person />,
    path: '/artists',
    text: 'Artists',
  },
  {
    icon: <Filter />,
    path: '/presets',
    text: 'Presets',
  },
  {
    icon: <Filter1 />,
    path: '/versions',
    text: 'Versions',
  },
  {
    icon: <Notifications />,
    path: '/notifications',
    text: 'Notifications',
  },
];

export default function SideMenu() {
  const isSideMenuOpen = useSelector(
    (state: StoreState) => state.app.isSideMenuOpen
  );

  const { pathname } = useLocation();

  const dispatch = useDispatch();

  const theme = useTheme();

  const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

  const onSideMenuClose = () => {
    dispatch(setIsSideMenuOpen(false));
  };
  const onSideMenuOpen = () => {
    dispatch(setIsSideMenuOpen(true));
  };

  const onSignOutClick = () => {
    AuthService.signOut();
  };

  const iOS =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  return (
    <SwipeableDrawer
      variant={matchesMdDown ? 'temporary' : 'permanent'}
      onClose={onSideMenuClose}
      onOpen={onSideMenuOpen}
      open={isSideMenuOpen}
      sx={{ width: SIDEBAR_WIDTH, height: '100%' }}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: matchesMdDown ? '100%' : SIDEBAR_WIDTH,
          borderRight: 'none',
          display: 'flex',
          flexDirection: 'column',
        },
        elevation: 0,
      }}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Link
          component={RouterLink}
          to="/artists"
          sx={{
            display: 'flex',
            alignItems: 'center',
            maxWidth: { xs: 200, sm: SIDEBAR_WIDTH },
          }}
          onClick={onSideMenuClose}
        >
          <Box
            component="img"
            src="/images/logo.png"
            alt="logo"
            sx={{ maxWidth: '100%', margin: 'auto' }}
          />
        </Link>
        {matchesMdDown && (
          <IconButton
            size="large"
            edge="end"
            sx={{ ml: 2 }}
            onClick={onSideMenuClose}
          >
            <Close />
          </IconButton>
        )}
      </Toolbar>

      <List
        sx={{
          px: 2,
          mt: 2,
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack spacing={1} flex="1 1 auto">
          {LINKS.map((link) => (
            <Link
              component={RouterLink}
              to={link.path}
              color="inherit"
              underline="none"
              key={`sidebar-${link.path}`}
            >
              <ListItemButton
                fullWidth
                component={Button}
                color="inherit"
                selected={link.path === pathname}
                startIcon={link.icon}
                size="large"
                onClick={onSideMenuClose}
              >
                <ListItemText>{link.text}</ListItemText>
              </ListItemButton>
            </Link>
          ))}
        </Stack>

        <Divider sx={{ mb: 1 }} />

        <ListItemButton
          sx={{ mt: 'auto', flex: '0 0 auto' }}
          fullWidth
          component={Button}
          color="inherit"
          startIcon={<Logout />}
          size="large"
          onClick={onSignOutClick}
        >
          <ListItemText>Sign out</ListItemText>
        </ListItemButton>

        <Divider sx={{ mt: 1, mb: 3 }} />
      </List>
    </SwipeableDrawer>
  );
}
