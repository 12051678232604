import { RegisterOptions } from 'react-hook-form';

interface Validators {
	[key: string]: RegisterOptions;
}

export const VALIDATORS: Validators = {
	EMAIL: {
		required: 'Please, enter an email',
		pattern: {
			value:
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
			message: 'Invalid format',
		},
	},
	PASSWORD: {
		required: 'Please, enter a password',
	},
};
