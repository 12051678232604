import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	styled,
} from '@mui/material';
import { useState } from 'react';
import { MutationFunction, useMutation } from 'react-query';

const DialogContainer = styled(Dialog)(({ theme }) => ({}));

interface DialogProps {
	title: string;
	isOpen: boolean;
	onMutation: MutationFunction<any>;
	onCancel: () => void;
	onSuccess: () => void;
}

export default function DeleteConfirmDialog({
	title,
	isOpen,
	onMutation,
	onCancel,
	onSuccess,
}: DialogProps) {
	const mutation = useMutation(onMutation, {
		onSuccess: () => {
			onSuccess();
		},
	});

	const onDialogCancel = () => {
		if (mutation.isLoading) {
			return;
		}

		onCancel();
	};
	const onDialogConfirm = async () => {
		if (mutation.isLoading) {
			return;
		}

		mutation.mutate(null);
	};

	return (
		<DialogContainer open={isOpen} onClose={onDialogCancel}>
			<DialogContent>{title}</DialogContent>
			<DialogActions>
				<Button
					color="error"
					onClick={onDialogCancel}
					disabled={mutation.isLoading}
					variant="outlined"
				>
					Cancel
				</Button>
				<LoadingButton
					onClick={onDialogConfirm}
					color="primary"
					variant="contained"
					loading={mutation.isLoading}
				>
					Confirm
				</LoadingButton>
			</DialogActions>
		</DialogContainer>
	);
}
