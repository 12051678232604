import { Add, Delete, Edit, Filter, RestartAlt } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Chip,
  IconButton,
  Paper,
  Tooltip,
  styled,
} from '@mui/material';
import { DataGrid, GridCallbackDetails, GridColDef } from '@mui/x-data-grid';
import { useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DeleteConfirmDialog from '../components/DeleteConfirmDialog';
import Header from '../components/Header';
import Loader from '../components/Loader';
import EditArtistDialog, {
  ArtistFormProps,
} from '../components/forms/EditArtistDialog';
import withAuth from '../components/withAuth';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from '../constants';
import useToast from '../hooks/useToast';
import ApiService from '../services/ApiService';
import { useAppDispatch } from '../store';
import { setIsAddArtistDialogOpen } from '../store/appSlice';
import { Artist } from '../types';

const ArtistsPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 1 auto',

  '.body': {
    margin: theme.spacing(3, 2),
    flex: '1 1 auto',
  },
}));

interface PaginationData {
  page: number;
  size: number;
  pageCount: number;
}

function ArtistsPage() {
  const [query] = useSearchParams();

  const [initialized, setInitialized] = useState(false);
  const [paginationData, setPaginationData] = useState<PaginationData>({
    page: JSON.parse(query.get('page') ?? '0'),
    size: 14,
    pageCount: 1,
  });

  const toast = useToast();

  const { isLoading, data, refetch } = useQuery(
    [['artists', paginationData.page]],
    () => ApiService.getArtists(paginationData.size, paginationData.page),
    {}
  );

  const restoreArtistMutation = useMutation(
    'restoreArtist',
    (artist: Artist) => ApiService.restoreArtist(artist),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        refetch();
      },
    }
  );

  const artists = data?.objects ?? [];

  useEffect(() => {
    if (!data) {
      return;
    }

    setInitialized(true);

    setPaginationData((prev) => ({
      ...prev,
      pageCount: data.pageCount,
      page: data.page,
    }));
    navigate({ search: `?page=${data.page}` });
  }, [data]);

  const onPageChange = (newPage: number, details: GridCallbackDetails) => {
    setPaginationData((prev) => ({ ...prev, page: newPage }));
    navigate({ search: `?page=${newPage}` });
  };

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [activeArtist, setActiveArtist] = useState<null | Artist>(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onArtistDeleteClick = (artist: Artist) => {
    return () => {
      setIsDeleteConfirmOpen(true);
      setActiveArtist(artist);
    };
  };

  const onArtistPresetsClick = (artist: Artist) => {
    return () => navigate({ pathname: `/artists/${artist.id}/presets/` });
  };

  const onArtistUpdateClick = (artist: Artist) => {
    return () => {
      dispatch(setIsAddArtistDialogOpen(true));
      setActiveArtist(artist);
    };
  };

  const onArtistRestoreClick = (artist: Artist) => () => {
    if (restoreArtistMutation.isLoading) {
      return;
    }

    restoreArtistMutation.mutate(artist);
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID',
      renderCell: (params) => {
        const row: Artist = params.row;

        return <Avatar src={row.avatarS3Url} />;
      },
      minWidth: 60,
      flex: 1,
      maxWidth: 100,
      filterable: false,
      hideable: false,
    },
    {
      field: 'fullName',
      headerName: 'Name',
      flex: 1,
      minWidth: 100,
      maxWidth: 180,
      filterable: false,
      hideable: false,
    },
    {
      field: 'email',
      headerName: 'Email',
      flex: 1,
      minWidth: 150,
      filterable: false,
      hideable: false,
    },
    {
      field: 'state',
      headerName: 'Status',
      renderCell: (params) => {
        const row: Artist = params.row;

        return <Chip label={row.state} />;
      },
      hideable: false,
    },
    {
      field: 'action',
      headerName: '',
      renderCell: (params) => {
        const value: Artist = params.row;

        return (
          <>
            <Tooltip title="Edit artist">
              <IconButton onClick={onArtistUpdateClick(value)}>
                <Edit />
              </IconButton>
            </Tooltip>
            {value.state === 'INVALID' ? (
              <Tooltip title="Restore artist">
                <LoadingButton
                  color="inherit"
                  onClick={onArtistRestoreClick(value)}
                  loading={
                    restoreArtistMutation.isLoading &&
                    activeArtist?.id === value.id
                  }
                  sx={{ minWidth: 36 }}
                >
                  <RestartAlt />
                </LoadingButton>
              </Tooltip>
            ) : (
              <Tooltip title="Delete artist">
                <IconButton color="error" onClick={onArtistDeleteClick(value)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="View presets">
              <IconButton color="inherit" onClick={onArtistPresetsClick(value)}>
                <Filter />
              </IconButton>
            </Tooltip>
          </>
        );
      },
      flex: 1,
      minWidth: 140,
      maxWidth: 140,
      hideable: false,
      sortable: false,
    },
  ];

  const onAddArtistClick = () => {
    dispatch(setIsAddArtistDialogOpen(true));
  };

  const artistDefaultValues = useMemo<
    Partial<ArtistFormProps> | undefined
  >(() => {
    if (!activeArtist) {
      return;
    }
    console.log(activeArtist);

    return {
      name: activeArtist.fullName,
      avatar: activeArtist.avatarS3Url,
      email: activeArtist.email,
      instagramURL: activeArtist.instagramUrl,
      position: activeArtist.position,
      hasArtistSubscriptionPlan: activeArtist.hasArtistSubscriptionPlan,
      price: activeArtist.artistSubscriptionPlanDto?.price,
      isSubscriptionActive: activeArtist.artistSubscriptionPlanDto?.isActive,
    } as Partial<ArtistFormProps>;
  }, [activeArtist]);

  return (
    <ArtistsPageContainer>
      <EditArtistDialog
        refetch={refetch}
        onClose={() => {
          setActiveArtist(null);
        }}
        artist={activeArtist ?? undefined}
        defaultValues={artistDefaultValues}
      />

      <DeleteConfirmDialog
        isOpen={isDeleteConfirmOpen}
        title={`Are you sure you want to delete ${activeArtist?.fullName}?`}
        onMutation={() => ApiService.deleteArtist(activeArtist?.id ?? 0)}
        onCancel={() => {
          setIsDeleteConfirmOpen(false);
        }}
        onSuccess={() => {
          setIsDeleteConfirmOpen(false);
          setActiveArtist(null);
          refetch();
        }}
      />
      <Header
        title="Artists"
        button={{
          text: 'Add Artist',
          startIcon: <Add />,
          onClick: onAddArtistClick,
        }}
      />
      <Paper elevation={0} className="body">
        {initialized ? (
          <DataGrid
            rows={artists}
            loading={isLoading}
            columns={columns}
            disableSelectionOnClick
            disableColumnMenu
            disableColumnSelector
            paginationMode="server"
            page={paginationData.page}
            pageSize={paginationData.size}
            rowCount={paginationData.pageCount * paginationData.size}
            onPageChange={onPageChange}
          />
        ) : (
          <Loader />
        )}
      </Paper>
    </ArtistsPageContainer>
  );
}

export default withAuth(ArtistsPage, true, false);
