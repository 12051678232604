import { v4 as uuid } from 'uuid';
import { FormEvent, useEffect, useRef } from 'react';
import { ControllerFieldState, ControllerRenderProps } from 'react-hook-form';
import { FileUploadFieldProps } from '.';
import useToast from '../../../hooks/useToast';
import {
	Box,
	ButtonBase,
	FormControl,
	FormHelperText,
	Input,
	styled,
} from '@mui/material';
import { IMAGE_UPLOAD_TYPES } from '../../../constants';
import useFileDragAndDrop from '../../../hooks/useFileDragAndDrop';
import Typography from '../../material/Typography';

interface Props {
	field: ControllerRenderProps;
	fieldState: ControllerFieldState;
	props: FileUploadFieldProps;
}

const FieldContainer = styled(FormControl, {
	shouldForwardProp: (prop: string) =>
		!['isDraggingOver', 'isDisabled'].includes(prop),
})<{
	isDraggingOver: boolean;
	isDisabled?: boolean;
}>(({ theme, isDraggingOver, error, isDisabled }) => ({
	width: '100%',
	maxWidth: '100%',
	minHeight: 100,
	height: '100%',

	'.MuiInput-root, input': {
		display: 'none',
	},

	'.field-label': {
		minHeight: theme.spacing(4),
		maxHeight: theme.spacing(4),
	},

	'.MuiButtonBase-root': {
		height: '100%',
		borderRadius: theme.shape.borderRadius,
		background: theme.palette.background.default,

		'.borders, .content': {
			position: 'absolute',
			padding: theme.spacing(2),
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: theme.transitions.create('all'),

			'&.borders': {
				opacity: 0.3,
				border: `dashed 2px ${
					error
						? theme.palette.error.light
						: theme.palette.action.disabledBackground
				}`,
				borderImageSource: `url("/images/dashed-image${
					error ? '-error' : ''
				}.png")`,
				borderImageSlice: 2,
				borderImageRepeat: 'round',
			},

			'&.content': {
				opacity: 0.8,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',

				'.MuiTypography-root': {
					color: error ? theme.palette.error.main : 'inherit',
					transition: theme.transitions.create('all'),
				},
			},

			...((isDraggingOver || error) &&
				!isDisabled && {
					opacity: `0.8 !important`,
				}),
		},

		...(!isDisabled && {
			'&:hover': {
				'.borders, .content': {
					opacity: 0.8,
				},

				'.content': {
					'.MuiTypography-root': {
						opacity: 1,
					},
				},
			},
		}),

		...(isDisabled && {
			opacity: 0.5,
		}),
	},
}));

export default function FileUploadFieldContainer({
	field,
	fieldState,
	props: { label, inputProps, formControlProps, allowedExtensions, ...props },
}: Props) {
	const toast = useToast();
	const inputRef = useRef();

	const { current: _id } = useRef(uuid());

	const onFormControlChange = (event: FormEvent<HTMLInputElement>) => {
		const files = (event.target as HTMLInputElement).files;

		if (!files || files.length === 0) {
			return;
		}

		const file = files[0];

		if (!file) {
			return;
		}

		const fileType = file.type;

		if (allowedExtensions && !allowedExtensions.includes(fileType)) {
			toast(
				`Unsupported file format. Allowed formats: ${allowedExtensions
					.join(', ')
					.replace(/image\//g, '.')}`,
				'error'
			);
			return;
		}

		field.onChange(files[0]);
	};

	const onFileRemove = (onChange: (value: any) => void) => () => {
		if (formControlProps?.disabled) {
			return;
		}

		onChange(undefined);

		if (inputRef.current) {
			(inputRef.current as HTMLInputElement).value = '';
		}
	};

	const onUpload = (file: File) => {
		field.onChange(file);
	};

	const { isDraggingOver, callbacks } = useFileDragAndDrop(
		onUpload,
		IMAGE_UPLOAD_TYPES
	);

	useEffect(() => {});

	return (
		<FieldContainer
			{...field}
			{...formControlProps}
			{...callbacks}
			onChange={onFormControlChange}
			isDraggingOver={isDraggingOver}
			error={Boolean(fieldState.error)}
			isDisabled={formControlProps?.disabled}
		>
			{/* {typeof field.value === 'string' ||
            typeof field.value?.file === 'string' ? (
                <Typography
                    variant="body2"
                    sx={{
                        position: 'relative',
                        zIndex: 10,
                    }}
                >
                    <MuiLink
                        href={
                            typeof field.value === 'string'
                                ? field.value
                                : field.value?.file
                        }
                        target="_blank"
                    >
                        Файл&nbsp;
                        <Launch fontSize="small" />
                    </MuiLink>
                </Typography>
            ) : (
                field.value && (
                    <Button
                        size="small"
                        endIcon={<HighlightOff />}
                        color="inherit"
                        onClick={onFileRemove(onChange)}
                        disabled={formControlProps?.disabled}
                    >
                        Удалить
                    </Button>
                )
            )} */}

			<ButtonBase
				component="label"
				htmlFor={_id}
				disabled={formControlProps?.disabled}
			>
				<Box className="borders"></Box>
				<Box className="content">
					<Typography variant="body1" maxLines={1} maxWidth="100%">
						{isDraggingOver
							? 'Drop the file here'
							: (typeof field.value === 'string'
									? field.value
									: field.value?.name) ?? label}
					</Typography>
				</Box>
			</ButtonBase>

			<Input
				type="file"
				{...inputProps}
				inputRef={inputRef}
				id={_id}
				name={inputProps?.id ?? props.name}
				inputProps={{
					...inputProps?.inputProps,

					...(allowedExtensions && {
						accept: allowedExtensions.join(','),
					}),
				}}
			/>

			{Boolean(fieldState.error) && (
				<FormHelperText>{fieldState.error?.message}</FormHelperText>
			)}
		</FieldContainer>
	);
}
