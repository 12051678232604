import { styled } from '@mui/material';

const PageContainer = styled('div')(({ theme }) => ({
	width: '100vw',
	height: '100vh',
	overflow: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
}));

export default PageContainer;
