import { Box, CircularProgress } from '@mui/material';

export default function Loader() {
	return (
		<Box
			sx={{
				flex: '1 1 auto',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<CircularProgress size={60} />
		</Box>
	);
}
