import { FormControlProps, InputProps } from '@mui/material';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import ImageUploadFieldContainer from './ImageUploadFieldContainer';

export interface ImageUploadFieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	ratio?: number;
	label?: string;
	inputProps?: InputProps;
	formControlProps?: FormControlProps;
	defaultFile?: File;
}

/* 
    An image upload field component. 
    Accepts ratio param which controls the aspect ratio of the image preview window. Example: 1 / 1, 3 / 4, 16 / 9, etc.
*/
const ImageUploadField = (props: ImageUploadFieldProps) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				return (
					<ImageUploadFieldContainer
						field={field}
						fieldState={fieldState}
						props={props}
					/>
				);
			}}
		/>
	);
};

export default ImageUploadField;
