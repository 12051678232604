import { Box, FormControl, FormHelperText, Input, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import {
	ControllerFieldState,
	ControllerRenderProps,
	Field,
} from 'react-hook-form';
import { ImageUploadFieldProps } from '.';
import { IMAGE_UPLOAD_TYPES } from '../../../constants';
import useFileDragAndDrop from '../../../hooks/useFileDragAndDrop';
import useFileObjectUrl from '../../../hooks/useFileObjectUrl';
import useFileUpload from '../../../hooks/useFileUpload';
import Typography from '../../material/Typography';

const FieldContainer = styled(FormControl, {
	shouldForwardProp: (prop: string) =>
		!['ratio', 'isDraggingOver', 'previewUrl', 'isDisabled'].includes(prop),
})<{
	ratio?: number;
	isDraggingOver: boolean;
	previewUrl?: string;
	isDisabled?: boolean;
}>(({ theme, ratio = 1, isDraggingOver, previewUrl, error, isDisabled }) => ({
	position: 'relative',
	width: '100%',

	'.MuiInput-root': {
		display: 'none',
	},

	'.field-label': {
		minHeight: theme.spacing(4),
		maxHeight: theme.spacing(4),
	},

	label: {
		'.image-preview': {
			cursor: isDisabled ? 'not-allowed' : 'pointer',
			backgroundColor: theme.palette.background.default,
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			borderRadius: theme.shape.borderRadius,
			paddingBottom: `${(1 / ratio) * 100}%`,
			position: 'relative',

			...(previewUrl && {
				backgroundImage: `url(${previewUrl})`,
			}),

			'.borders, .content': {
				position: 'absolute',
				top: 0,
				left: 0,
				right: 0,
				bottom: 0,
				transition: theme.transitions.create('all'),

				'&.borders': {
					opacity: 0.3,
					border: `dashed 2px ${
						error
							? theme.palette.error.light
							: theme.palette.action.disabledBackground
					}`,
					borderImageSource: `url("/images/dashed-image${
						error ? '-error' : ''
					}.png")`,
					borderImageSlice: 2,
					borderImageRepeat: 'round',
				},

				'&.content': {
					opacity: 0.8,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',

					'.MuiTypography-root': {
						maxWidth: 200,
						color: error ? theme.palette.error.main : 'inherit',
						opacity: previewUrl ? 0 : 1,
						transition: theme.transitions.create('all'),
					},
				},

				...((isDraggingOver || error) &&
					!isDisabled && {
						opacity: `0.8 !important`,
					}),
			},

			...(!isDisabled && {
				'&:hover': {
					'.borders, .content': {
						opacity: 0.8,
					},

					'.content': {
						...(previewUrl && {
							background: `${theme.palette.background.default}9f`,
						}),

						'.MuiTypography-root': {
							opacity: 1,
						},
					},
				},
			}),

			...(isDisabled && {
				opacity: 0.5,
			}),
		},
	},
}));

interface Props {
	field: ControllerRenderProps;
	fieldState: ControllerFieldState;
	props: ImageUploadFieldProps;
}

export default function ImageUploadFieldContainer({
	field,
	fieldState,
	props,
}: Props) {
	const [file, setFile] = useState<File | string | undefined>(field.value);
	const isDisabled = props.formControlProps?.disabled;

	const id = props.inputProps?.id ?? props.name;

	const onUpload = (file?: File) => {
		setFile(file);
		field.onChange(file);
	};

	const previewUrl = useFileObjectUrl(file);
	const onFileChange = useFileUpload(onUpload, IMAGE_UPLOAD_TYPES);

	const { isDraggingOver, callbacks } = useFileDragAndDrop(
		onUpload,
		IMAGE_UPLOAD_TYPES
	);

	useEffect(() => {
		setFile(field.value);
	}, [field.value]);

	return (
		<FieldContainer
			previewUrl={previewUrl}
			isDraggingOver={isDraggingOver}
			ratio={props.ratio}
			{...props.formControlProps}
			error={Boolean(fieldState.error)}
			isDisabled={isDisabled}
		>
			<label htmlFor={id}>
				<Box className="image-preview" {...callbacks}>
					<Box className="borders"></Box>
					<Box className="content">
						<Typography variant="body1" color="text.primary">
							{isDraggingOver ? 'Drop the image here' : props.label}
						</Typography>
					</Box>
				</Box>
			</label>
			<Input
				type="file"
				{...props.inputProps}
				id={id}
				onChange={onFileChange}
				inputProps={{
					accept: IMAGE_UPLOAD_TYPES.join(', '),
				}}
			/>
			{Boolean(fieldState.error) && (
				<FormHelperText sx={{ mt: 0.5 }}>
					{fieldState.error?.message}
				</FormHelperText>
			)}
		</FieldContainer>
	);
}
