import { createTheme, responsiveFontSizes } from '@mui/material';

let theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#1976D2',
    },
    secondary: {
      main: '#FF453A',
    },
    error: {
      main: '#FF453A',
    },
    background: {
      default: '#1C1C1E',
      paper: '#2C2C2E',
    },
  },
  typography: {
    fontFamily: 'Open Sans, Arial, Helvetica Neue, sans-serif',
    subtitle1: {
      fontWeight: 600,
    },
  },
  shape: {
    borderRadius: 16,
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: ({ theme }) => ({
          fontWeight: 600,
        }),
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          borderRadius: 10,

          '&.Mui-selected': {
            backgroundColor: theme.palette.background.default,
          },
        }),
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.padded:not(.MuiAlert-root):not(.MuiAppBar-root):not(.MuiDrawer-paper)':
            {
              padding: theme.spacing(4),
              paddingTop: theme.spacing(5),

              [theme.breakpoints.down('lg')]: {
                padding: theme.spacing(3),
                paddingTop: theme.spacing(4),
              },

              [theme.breakpoints.down('md')]: {
                padding: theme.spacing(2),
                paddingTop: theme.spacing(3),
              },
            },
        }),
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: ({ theme }) => ({
          background: theme.palette.background.default,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          textTransform: 'capitalize',
          fontWeight: 500,
          fontSize: '1rem',
        }),
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: ({ theme }) => ({
          [theme.breakpoints.up('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
          },
        }),
      },
    },
  },
});

theme = responsiveFontSizes(theme);

export default theme;
