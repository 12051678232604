import axios from 'axios';
import { API_URL } from '../constants';
import { Notification, Topic } from '../types';

export interface PostNotification {
	title: string;
	body: string;
	token?: string;
	topics?: string;
	image: File;
	notificationType:
		| 'PRESET_UPDATE'
		| 'ARTIST_UPDATE'
		| 'VERSION_UPDATE'
		| 'COFFEE_BREAK'
		| 'EVENT';
	data?: {
		id?: number;
	};
}

const NotificationsService = {
	async getNotifications(
		page: number,
		size: number
	): Promise<{
		objects: Notification[];
		page: number;
		size: number;
		pageCount: number;
		lastUpdatedDate: string;
	}> {
		const url = `${API_URL}/v1/admin/notifications`;

		const res = await axios.get<{
			objects: Notification[];
			page: number;
			size: number;
			pageCount: number;
			lastUpdatedDate: string;
		}>(url, {
			params: {
				page,
				size,
			},
		});

		return res.data;
	},
	async createNewNotification({ image, ...data }: PostNotification) {
		const url = `${API_URL}/v1/admin/notifications`;

		const formData = new FormData();

		formData.append('notificationDto', JSON.stringify(data));

		formData.append('notificationImage', image);

		await axios.post(url, formData);
	},
	async getTopics(): Promise<Topic[]> {
		const url = `${API_URL}/v1/admin/topics`;

		const res = await axios.get<{ objects: Topic[] }>(url, {
			params: { page: 0, size: 1000 },
		});

		return res.data.objects;
	},
};

export default NotificationsService;
