import axios from 'axios';
import { API_URL } from '../constants';
import {
  IPaginatedResponse,
  ISubscriptionPlan,
  ISubscriptionPlanCreate,
} from '../types';

const STRIPE_PRODUCT = {
  id: 1,
  name: 'REDart Premium Artist',
  prodId: 'prod_NpeNMD6hksZ2S0',
};

export const SubscriptionPlansService = {
  getSubscriptionPlans: async () => {
    const res = await axios.get<IPaginatedResponse<ISubscriptionPlan>>(
      `${API_URL}/v2/admin/subscription-plans`
    );

    return res.data;
  },
  createSubscriptionPlan: async (data: ISubscriptionPlanCreate) => {
    const res = await axios.post<ISubscriptionPlan>(
      `${API_URL}/v2/admin/artist-subscription`,
      {
        ...data,
        stripeProduct: STRIPE_PRODUCT,
        priceId: new Date().getTime().toString(),
      }
    );

    return res.data;
  },
  updateSubscriptionPlan: async (
    id: number,
    data: Partial<ISubscriptionPlanCreate>
  ) => {
    const res = await axios.patch<ISubscriptionPlan>(
      `${API_URL}/v2/admin/artist-subscription/${id}`,
      data
    );

    return res.data;
  },
};
