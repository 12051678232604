import axios from 'axios';
import { API_URL } from '../constants';
import { ERROR_MESSAGES } from '../constants/errors';
import { ServiceError } from '../types';
import getErrorMessageByKey from '../util/getErrorMessageByKey';
import TokenService from './TokenService';

interface SignInResult {
	id: number;
	authToken: string;
	refreshToken: string;
}

async function signIn(email: string, password: string): Promise<any> {
	const url = `${API_URL}/v2/authentication/login`;

	try {
		const res = await axios.post(url, { email, password });

		const data: SignInResult = res.data;

		return data;
	} catch (e: any) {
		// Get the status of the error
		const status = e.response?.status;

		const messages = {
			401: ERROR_MESSAGES.INVALID_CREDENTIALS,
		};

		// Get corresponding error message or return default
		let errorMessage = getErrorMessageByKey(status, messages);

		const res: ServiceError = {
			errorMessage,
		};

		return res;
	}
}

function signOut() {
	TokenService.removeTokens();
	window.location.reload();
}

const AuthService = {
	signIn,
	signOut,
};

export default AuthService;
