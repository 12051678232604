import {
	AsyncThunkAction,
	createAsyncThunk,
	createSlice,
} from '@reduxjs/toolkit';
import AuthService from '../services/AuthService';
import TokenService from '../services/TokenService';
import { ServiceError } from '../types';

interface SignInData {
	email: string;
	password: string;
}

export const signIn = createAsyncThunk<boolean | ServiceError, SignInData>(
	'auth/signIn',
	async (data, thunkAPI) => {
		const { email, password } = data;

		const res = await AuthService.signIn(email, password);

		if (res.errorMessage) {
			return thunkAPI.rejectWithValue(res.errorMessage);
		}

		TokenService.setToken(res.authToken);
		TokenService.setRefreshToken(res.refreshToken);

		return true;
	}
);

interface AppErrorState {
	message: string;
	display: boolean;
}

export interface AppState {
	isLoading: boolean;
	isAuthed: boolean;
	isSideMenuOpen: boolean;
	isAddVersionDialogOpen: boolean;
	isAddArtistDialogOpen: boolean;
	isUpdateArtistDialogOpen: boolean;
	isAddPresetDialogOpen: boolean;
	isUpdatePresetDialogOpen: boolean;
	isAddNotificationDialogOpen: boolean;
	errorData: AppErrorState;
}

const initialState: AppState = {
	isLoading: false,
	isAuthed: TokenService.tokenExists(),
	isSideMenuOpen: false,
	isAddVersionDialogOpen: false,
	isAddArtistDialogOpen: false,
	isUpdateArtistDialogOpen: false,
	isAddPresetDialogOpen: false,
	isUpdatePresetDialogOpen: false,
	isAddNotificationDialogOpen: false,
	errorData: { display: false, message: '' },
};

const appSlice = createSlice({
	name: 'app',
	initialState: initialState,
	reducers: {
		setIsAddNotificationDialogOpen: (state, action: { payload: boolean }) => ({
			...state,
			isAddNotificationDialogOpen: action.payload,
		}),
		setIsAddVersionDialogOpen: (state, action: { payload: boolean }) => ({
			...state,
			isAddVersionDialogOpen: action.payload,
		}),
		setIsAddArtistDialogOpen: (state, action: { payload: boolean }) => ({
			...state,
			isAddArtistDialogOpen: action.payload,
		}),
		setIsUpdateArtistDialogOpen: (state, action: { payload: boolean }) => ({
			...state,
			isUpdateArtistDialogOpen: action.payload,
		}),
		setIsAddPresetDialogOpen: (state, action: { payload: boolean }) => ({
			...state,
			isAddPresetDialogOpen: action.payload,
		}),
		setIsUpdatePresetDialogOpen: (state, action: { payload: boolean }) => ({
			...state,
			isUpdatePresetDialogOpen: action.payload,
		}),
		setIsLoading: (state, action: { payload: boolean }) => ({
			...state,
			isLoading: action.payload,
		}),
		setIsSideMenuOpen: (state, action: { payload: boolean }) => ({
			...state,
			isSideMenuOpen: action.payload,
		}),
		setIsAuthed: (state, action: { payload: boolean }) => ({
			...state,
			isAuthed: action.payload,
		}),
		setError: (state, action: { payload: string }) => ({
			...state,
			errorData: {
				...state.errorData,
				display: true,
				message: action.payload,
			},
		}),
		hideError: (state) => ({
			...state,
			errorData: { ...state.errorData, display: false },
		}),
		resetError: (state) => ({
			...state,
			errorData: { ...state.errorData, ...initialState.errorData },
		}),
	},
	extraReducers: (builder) => {
		builder
			.addCase(signIn.pending, (state, action) => {
				state.isLoading = true;
				state.isAuthed = false;
				state.errorData.display = false;
			})
			.addCase(signIn.fulfilled, (state, action) => {
				state.isLoading = false;
				state.isAuthed = true;
				state.errorData = {
					...state.errorData,
					...initialState.errorData,
				};
			})
			.addCase(signIn.rejected, (state, action: any) => {
				state.isLoading = false;
				state.isAuthed = false;
				state.errorData.message = action.payload;
				state.errorData.display = true;
			});
	},
});

export const {
	setIsAddNotificationDialogOpen,
	setIsAddVersionDialogOpen,
	setIsAddArtistDialogOpen,
	setIsUpdateArtistDialogOpen,
	setIsAddPresetDialogOpen,
	setIsUpdatePresetDialogOpen,
	setIsAuthed,
	setIsLoading,
	setIsSideMenuOpen,
	hideError,
	resetError,
	setError,
} = appSlice.actions;

const appReducer = appSlice.reducer;

export default appReducer;
