import { PresetPackage } from '../services/ApiService';
import { StoreItem } from '../types';

export const API_URL =
  process.env.REACT_APP_BUILD === 'production'
    ? 'https://api.redart.app/api'
    : process.env.REACT_APP_BUILD === 'staging'
    ? 'https://staging.redart.app/api'
    : 'https://api.redart.space/api';
// export const API_URL = 'http://192.168.200.14:8001/api';

export const SIDEBAR_WIDTH = 250;

export const IMAGE_UPLOAD_TYPES = [
  'image/png',
  'image/jpeg',
  'image/webp',
  'image/heic',
];

export const STORE_ITEM_FREE: StoreItem = {
  appStoreId: 'Free',
  id: -1,
  playMarketId: '',
  value: 0,
  currency: '',
  state: 'ACTIVE',
};

export const PRESET_PACKAGE_NONE: PresetPackage = {
  id: -1,
  name: 'None',
  price: 0,
  coverImageUrl: '',
  description: '',
  presetsNumber: 0,
  state: 'ACTIVE',
  ownerUserId: -1,
};

export const GRAIN_SOURCES: { [key: string]: string } = {
  NONE: 'None',
  VSCO: 'VSCO',
  LIGHTROOM: 'LIGHTROOM',
  SNAPSEED: 'SNAPSEED',
};

export const GRAIN_SOURCES_LIST = [
  GRAIN_SOURCES.NONE,
  GRAIN_SOURCES.VSCO,
  GRAIN_SOURCES.LIGHTROOM,
  GRAIN_SOURCES.SNAPSEED,
];

export const DEFAULT_ERROR_MESSAGE =
  'There was an error processing the request. Please, try again.';
export const DEFAULT_SUCCESS_MESSAGE = 'Successfully saved the data!';
