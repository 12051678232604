import { CssBaseline, ThemeProvider } from '@mui/material';
import axios, { AxiosRequestConfig } from 'axios';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { API_URL } from './constants';
import { AppContextProvider } from './contexts/AppContext';
import { ToastContextProvider } from './contexts/ToastContext';
import MainLayout from './layouts/MainLayout';
import ArtistsPage from './pages/Artists';
import PresetsPage from './pages/Presets';
import SignInPage from './pages/SignIn';
import VersionsPage from './pages/Versions';
import NotificationsPage from './pages/Notifications';
import TokenService from './services/TokenService';
import store from './store';
import AppStyles from './styles';
import theme from './theme';
import SubscriptionsPage from './pages/SubscriptionPlans';

axios.interceptors.request.use((config: AxiosRequestConfig) => {
	if (TokenService.tokenExists()) {
		const token = TokenService.getToken();

		if (config.headers) {
			config.headers.Authorization = `Bearer ${token}`;
		}
	}

	return config;
});

axios.interceptors.response.use(
	(response) => response,
	async (error: any) => {
		const errorCode = error.response.status;
		console.log(errorCode);

		const errorMessage = error.response.data?.errorMessage;

		if (errorCode === 401) {
			if (
				TokenService.refreshTokenExists() &&
				errorMessage !==
					'JWT token string is empty or corrupted any other way - {JWT String argument cannot be null or empty.}'
			) {
				try {
					const res = await axios.post(
						`${API_URL}/v2/authentication/refresh-token/`,
						{},
						{
							headers: {
								refreshToken: TokenService.getRefreshToken() ?? '',
							},
						}
					);

					const { authToken } = res.data;

					TokenService.setToken(authToken);
					return Promise.reject(error);
				} catch (e) {
					TokenService.removeTokens();
					window.location.reload();
					return Promise.reject(e);
				}
			} else {
				TokenService.removeTokens();
				window.location.reload();
			}
		}

		return Promise.reject(error);
	}
);

const queryClient = new QueryClient();

function App() {
	return (
		<div className="App">
			<QueryClientProvider client={queryClient}>
				<Provider store={store}>
					<ThemeProvider theme={theme}>
						<BrowserRouter>
							<AppContextProvider>
								<ToastContextProvider>
									<CssBaseline />
									<AppStyles />
									<Routes>
										<Route path="/" element={<MainLayout />}>
											<Route index element={<Navigate to="/artists" />} />
											<Route path="/artists" element={<ArtistsPage />} />
											<Route
												path="/artists/:artistId/presets"
												element={<PresetsPage />}
											/>
											<Route path="/presets" element={<PresetsPage />} />
											<Route path="/versions" element={<VersionsPage />} />
											<Route
												path="/subscriptions"
												element={<SubscriptionsPage />}
											/>
											<Route
												path="/notifications"
												element={<NotificationsPage />}
											/>
										</Route>
										<Route path="/sign-in" element={<SignInPage />} />
									</Routes>
								</ToastContextProvider>
							</AppContextProvider>
						</BrowserRouter>
					</ThemeProvider>
				</Provider>
			</QueryClientProvider>
		</div>
	);
}

export default App;
