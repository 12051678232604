import { DragEventHandler, useContext, useState } from 'react';
import { ToastContext } from '../contexts/ToastContext';

interface HookReturnValue {
	isDraggingOver: boolean;
	callbacks: {
		onDragOver: DragEventHandler;
		onDragLeave: DragEventHandler;
		onDrop: DragEventHandler;
	};
}

/* 
	Adds a drag and drop functionality to the component.

	onUpload - a callback that gets called after the drop event and after checking that 
	the file matches the file types from the fileTypes array
*/

export default function useFileDragAndDrop(
	onUpload: (file: File) => void,
	fileTypes: Array<string>
): HookReturnValue {
	const { toast } = useContext(ToastContext);
	const [isDraggingOver, setIsDraggingOver] = useState(false);

	const onDragOver: DragEventHandler = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDraggingOver(true);
	};

	const onDragLeave: DragEventHandler = (e) => {
		e.preventDefault();
		e.stopPropagation();
		setIsDraggingOver(false);
	};

	const onDrop: DragEventHandler = (e) => {
		setIsDraggingOver(false);
		e.preventDefault();
		e.stopPropagation();

		const { files } = e.dataTransfer;

		const newFile = files.item(0);

		if (!newFile) {
			return;
		}

		if (!fileTypes.includes(newFile.type)) {
			toast(
				`Unsupported file format. Allowed formats: ${fileTypes
					.join(', ')
					.replace(/image\//g, '.')}`,
				'error'
			);

			return;
		}

		onUpload(newFile);
	};

	return { isDraggingOver, callbacks: { onDragOver, onDragLeave, onDrop } };
}
