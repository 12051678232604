import { Add, Delete, Edit } from '@mui/icons-material';
import { Box, IconButton, Paper, styled } from '@mui/material';
import {
	DataGrid,
	GridCallbackDetails,
	GridColDef,
	GridColumns,
	GridSortItem,
} from '@mui/x-data-grid';
import { useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DeleteConfirmDialog from '../components/DeleteConfirmDialog';
import AddNotificationDialog from '../components/forms/AddNotificationDialog';
import EditVersionDialog from '../components/forms/EditVersionDialog';
import Header from '../components/Header';
import withAuth from '../components/withAuth';
import { DEFAULT_ERROR_MESSAGE, DEFAULT_SUCCESS_MESSAGE } from '../constants';
import useToast from '../hooks/useToast';
import ApiService from '../services/ApiService';
import NotificationsService from '../services/NotificationsService';
import { useAppDispatch } from '../store';
import {
	setIsAddNotificationDialogOpen,
	setIsAddVersionDialogOpen,
} from '../store/appSlice';
import { Notification, PostVersion, Version } from '../types';

interface PaginationData {
	page: number;
	size: number;
	pageCount: number;
}

const VersionsPageContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	flexDirection: 'column',
	flex: '1 1 auto',

	'.body': {
		margin: theme.spacing(3, 2),
		flex: '1 1 auto',
	},
}));

function NotificationsPage() {
	const [query] = useSearchParams();
	const navigate = useNavigate();

	const dispatch = useAppDispatch();
	const toast = useToast();

	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

	const [paginationData, setPaginationData] = useState<PaginationData>({
		page: JSON.parse(query.get('page') ?? '0') ?? 0,
		size: 14,
		pageCount: 1000,
	});

	const {
		data: notifications,
		isLoading,
		refetch,
	} = useQuery(
		['getNotifications', paginationData.page],
		() =>
			NotificationsService.getNotifications(
				paginationData.page,
				paginationData.size
			),
		{
			onSuccess(data) {
				setPaginationData((prev) => ({
					...prev,
					pageCount: data.pageCount,
					page: data.page,
				}));
				navigate({ search: `?page=${data.page}` });
			},
		}
	);
	const onAddNotificationClick = () => {
		dispatch(setIsAddNotificationDialogOpen(true));
	};

	const onPageChange = (newPage: number, details: GridCallbackDetails) => {
		setPaginationData((prev) => ({ ...prev, page: newPage }));
		navigate({ search: `?page=${newPage}` });
	};
	const columns: GridColDef<Notification>[] = [
		{
			field: 'image',
			headerName: 'Image',
			minWidth: 50,
			maxWidth: 80,
			flex: 0,
			filterable: false,
			hideable: false,
			renderCell: ({ value }) => (
				<Box
					sx={(theme) => ({
						borderRadius: theme.shape.borderRadius,
						maxWidth: 50,

						img: { maxWidth: '100%' },
					})}
				>
					<img src={value} />
				</Box>
			),
		},
		{
			field: 'title',
			headerName: 'Title',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'notificationType',
			headerName: 'Type',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'body',
			headerName: 'Body',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'token',
			headerName: 'Token',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'topics',
			headerName: 'Topics',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
		},
		{
			field: 'createdTime',
			headerName: 'Created Time',
			minWidth: 180,
			flex: 1,
			filterable: false,
			hideable: false,
			renderCell: ({ value }) =>
				new Date(value ?? '').toLocaleDateString('en-GB', {
					hour: '2-digit',
					minute: '2-digit',
					second: '2-digit',
				}),
		},
	];

	return (
		<VersionsPageContainer>
			<Header
				title="Versions"
				button={{
					text: 'New Notification',
					startIcon: <Add />,
					onClick: onAddNotificationClick,
				}}
			/>

			<AddNotificationDialog />

			<Paper elevation={0} className="body">
				<DataGrid
					getRowId={(row: Notification) => row.title}
					rows={notifications?.objects ?? []}
					loading={isLoading}
					columns={columns}
					disableSelectionOnClick
					disableColumnMenu
					disableColumnSelector
					sortModel={[{ field: 'id', sort: 'desc' }]}
					paginationMode="server"
					page={paginationData.page}
					pageSize={paginationData.size}
					rowCount={paginationData.pageCount * paginationData.size}
					onPageChange={onPageChange}
				/>
			</Paper>
		</VersionsPageContainer>
	);
}

export default withAuth(NotificationsPage, true, false);
