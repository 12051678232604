/* 
    Turns file into a string URL
*/

import { useEffect, useState } from 'react';

export default function useFileObjectUrl(value?: File | string) {
	const [url, setUrl] = useState<undefined | string>();

	useEffect(() => {
		if (!value) {
			setUrl(undefined);
			return;
		}

		if (typeof value === 'string') {
			setUrl(value);
			return;
		}

		const newUrl = URL.createObjectURL(value);

		setUrl(newUrl);
	}, [value]);

	return url;
}
