import { ChangeEventHandler } from 'react';

export default function useFileUpload(
	onUpload: (file?: File) => void,
	fileTypes: Array<string>,
	disabled?: boolean
): ChangeEventHandler {
	const onChange: ChangeEventHandler<HTMLInputElement> = (event) => {
		const files = event.target.files;

		if (!files || files.length === 0 || disabled) {
			return;
		}

		const newFile = files.item(0);

		if (!newFile) {
			return;
		}

		if (!fileTypes.includes(newFile.type)) {
			onUpload(undefined);
			event.target.value = '';
			return;
		}

		onUpload(newFile);
		event.target.value = '';
	};

	return onChange;
}
