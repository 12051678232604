import { LoadingButton } from '@mui/lab';
import { Box, Container, Paper, Stack, styled, TextField } from '@mui/material';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import PageContainer from '../components/PageContainer';
import PasswordField from '../components/PasswordField';
import withAuth from '../components/withAuth';
import { VALIDATORS } from '../constants/validators';
import { StoreState, useAppDispatch } from '../store';
import { signIn } from '../store/appSlice';

const SignInPageContainer = styled(PageContainer)(({ theme }) => ({
	'.MuiPaper-root': {
		maxWidth: 410,
		margin: 'auto',
	},
}));

interface FormValues {
	email: string;
	password: string;
}

const initialValues: FormValues = {
	email: '',
	password: '',
};

function SignInPage() {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ defaultValues: initialValues });

	const isLoading = useSelector((state: StoreState) => state.app.isLoading);
	const dispatch = useAppDispatch();

	const onSubmit = (data: FormValues) => {
		if (isLoading) {
			return;
		}

		data.email = data.email.trim();
		data.password = data.password.trim();

		dispatch(signIn({ ...data }));
	};

	return (
		<SignInPageContainer>
			<Container maxWidth="sm">
				<Paper className="padded">
					<Stack
						spacing={4}
						component="form"
						onSubmit={handleSubmit(onSubmit)}
						noValidate
					>
						<Box
							component="img"
							src="/images/logo.png"
							alt="logo"
							sx={{ maxWidth: '80%', margin: 'auto' }}
						/>

						<Stack spacing={2}>
							<TextField
								type="email"
								label="Email"
								variant="outlined"
								inputProps={{ ...register('email', { ...VALIDATORS.EMAIL }) }}
								error={Boolean(errors.email)}
								helperText={errors.email?.message}
								disabled={isLoading}
							/>
							<PasswordField
								label="Password"
								variant="outlined"
								inputProps={{
									...register('password', { ...VALIDATORS.PASSWORD }),
								}}
								error={Boolean(errors.password)}
								helperText={errors.password?.message}
								disabled={isLoading}
							/>
						</Stack>

						<LoadingButton
							variant="contained"
							size="large"
							type="submit"
							loading={isLoading}
						>
							Sign In
						</LoadingButton>
					</Stack>
				</Paper>
			</Container>
		</SignInPageContainer>
	);
}

export default withAuth(SignInPage, false, true);
