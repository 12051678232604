import { ComponentType, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import TokenService from '../services/TokenService';
import { StoreState, useAppDispatch } from '../store';
import { setIsAuthed } from '../store/appSlice';

// Auth guard
export default function withAuth(
	Component: ComponentType,
	signedInRequired: boolean,
	signedInForbidden: boolean
) {
	return () => {
		const authed = useSelector((state: StoreState) => state.app.isAuthed);
		const dispatch = useAppDispatch();

		// Start token checker
		useEffect(() => {
			// Check the token on background
			const authChecker = setInterval(() => {
				const newAuthed = TokenService.tokenExists();

				if (newAuthed !== authed) {
					dispatch(setIsAuthed(newAuthed));
				}
			}, 1000);

			return () => {
				clearInterval(authChecker);
			};
		}, [authed]);

		// If anauthenticated and component requires sign in
		if (signedInRequired && !authed) {
			return <Navigate to="/sign-in" replace />;
		}

		// If authenticated and component forbides signed in users
		if (signedInForbidden && authed) {
			return <Navigate to="/" />;
		}

		return <Component />;
	};
}
