import { FormControlProps, InputProps } from '@mui/material';
import { Control, Controller, ControllerProps } from 'react-hook-form';
import FileUploadFieldContainer from './FileUploadFieldContainer';

export interface FileUploadFieldProps extends Partial<ControllerProps> {
	name: string;
	control: Control<any>;
	render?: any;
	label?: string;
	inputProps?: InputProps;
	formControlProps?: FormControlProps;
	allowedExtensions?: string[];
}

/* 
    A file upload field component with label.
*/
const FileUploadField = (props: FileUploadFieldProps) => {
	return (
		<Controller
			{...props}
			render={({ field, fieldState }) => {
				// if (typeof field.value === 'string') {
				// 	return (
				// 		<Box>
				// 			<FormLabel htmlFor={_id} sx={{ mb: 1 }}>
				// 				{label}
				// 			</FormLabel>

				// 			<Typography
				// 				variant="body2"
				// 				sx={{ position: 'relative', zIndex: 10, mt: 2 }}
				// 			>
				// 				<MuiLink href={field.value} target="_blank">
				// 					Посмотреть файл <Launch fontSize="small" />
				// 				</MuiLink>
				// 			</Typography>
				// 		</Box>
				// 	);
				// }

				return (
					<FileUploadFieldContainer
						field={field}
						fieldState={fieldState}
						props={props}
					/>
				);
			}}
		/>
	);
};

export default FileUploadField;
