import { Link as LinkIcon } from '@mui/icons-material';
import { Link } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

export default function renderGridCellAsLink(params: GridRenderCellParams) {
	const value = params.value;

	return value ? (
		<Link href={value} target="_blank">
			<LinkIcon fontSize="small" /> Link
		</Link>
	) : (
		'No Link'
	);
}
