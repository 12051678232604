import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	MenuItem,
	PaperProps,
	Stack,
	Switch,
	TextField,
} from '@mui/material';
import { getValue } from '@testing-library/user-event/dist/utils';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { DEFAULT_SUCCESS_MESSAGE } from '../../constants';
import { ERROR_MESSAGES } from '../../constants/errors';
import { VALIDATORS } from '../../constants/validators';
import useToast from '../../hooks/useToast';
import ApiService from '../../services/ApiService';
import NotificationsService, {
	PostNotification,
} from '../../services/NotificationsService';
import { useAppDispatch, useAppSelector } from '../../store';
import { setIsAddNotificationDialogOpen } from '../../store/appSlice';
import { Artist, Preset } from '../../types';
import AutocompleteField from '../fields/AutocompleteField';
import ImageUploadField from '../fields/ImageUploadField';
import Select from '../fields/SelectField';
import Typography from '../material/Typography';

type FormValues = Omit<PostNotification, 'data'> & {
	isToken: false;
	data: {
		id: Artist | Preset;
	};
};

interface PaperPropsType extends PaperProps {
	component: 'form';
}

export default function AddNotificationDialog() {
	const toast = useToast();
	const isOpen = useAppSelector(
		(state) => state.app.isAddNotificationDialogOpen
	);
	const dispatch = useAppDispatch();
	const queryClient = useQueryClient();
	const [idQuery, setIdQuery] = useState('');

	const { data: topics, isLoading: isLoadingTopics } = useQuery(
		'getTopics',
		() => NotificationsService.getTopics()
	);

	const { data: presets, isLoading: isLoadingPresets } = useQuery(
		['getPresets', idQuery],
		() => ApiService.getPresets(undefined, 50, 0, idQuery)
	);

	const { data: artists, isLoading: isLoadingArtists } = useQuery(
		['getArtists', idQuery],
		() => ApiService.getArtists(50, 0, idQuery)
	);

	const saveNotificationMutation = useMutation(
		'saveNotification',
		(data: PostNotification) =>
			NotificationsService.createNewNotification(data),
		{
			onError(error, variables, context) {
				toast(ERROR_MESSAGES.DEFAULT);
			},
			onSuccess(data, variables, context) {
				toast(DEFAULT_SUCCESS_MESSAGE, 'success');

				dispatch(setIsAddNotificationDialogOpen(false));
				reset({ image: undefined, topics: undefined });
				queryClient.refetchQueries('getNotifications');
			},
		}
	);

	const isLoading = saveNotificationMutation.isLoading;

	const {
		register,
		control,
		formState: { errors },
		handleSubmit,
		watch,
		reset,
		resetField,
		getValues,
		setValue,
	} = useForm<FormValues>({
		defaultValues: {
			image: undefined,
			topics: undefined,
			notificationType: 'PRESET_UPDATE',
		},
	});

	const notificationType = watch('notificationType');
	const isToken = watch('isToken');
	const id = watch('image');

	console.log(id);

	const onDialogClose = () => {
		if (isLoading) {
			return;
		}

		dispatch(setIsAddNotificationDialogOpen(false));
		reset({ image: undefined, topics: undefined });
	};

	const onSubmit = ({
		isToken,
		topics,
		token,
		data,
		...formData
	}: FormValues) => {
		if (isLoading) {
			return;
		}

		console.log(topics, token);

		saveNotificationMutation.mutate({
			...formData,
			...(isToken ? { token } : { topics }),
			...(data.id && {
				data: {
					id: data.id.id,
				},
			}),
		});
	};

	const DialogPaperProps: PaperPropsType = {
		component: 'form',
		onSubmit: handleSubmit(onSubmit),
	};

	const idLabel = notificationType === 'ARTIST_UPDATE' ? 'Artist' : 'Preset';

	return (
		<Dialog
			open={isOpen}
			onClose={onDialogClose}
			fullWidth
			maxWidth="md"
			PaperProps={DialogPaperProps}
		>
			<DialogTitle
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
				}}
			>
				New Push Notification
				<IconButton edge="end" onClick={onDialogClose} disabled={isLoading}>
					<Close />
				</IconButton>
			</DialogTitle>
			<DialogContent>
				<Box pt={1}>
					<Grid container spacing={2}>
						<Grid item xs={12} sm={4} md={3} margin="auto" maxWidth={200}>
							<ImageUploadField
								control={control}
								name="image"
								label="Image"
								formControlProps={{ disabled: isLoading }}
							/>
						</Grid>
						<Grid item container xs={12} sm={8} md={9} spacing={2}>
							<Grid item xs={12}>
								<TextField
									disabled={isLoading}
									fullWidth
									label="Title"
									inputProps={{
										...register('title', { required: 'Please, enter title' }),
									}}
									error={Boolean(errors.title)}
									helperText={errors.title?.message}
								/>
							</Grid>
							<Grid item xs={12}>
								<TextField
									disabled={isLoading}
									fullWidth
									type="body"
									label="Body"
									inputProps={{
										...register('body', { required: 'Please, enter body' }),
									}}
									error={Boolean(errors.body)}
									helperText={errors.body?.message}
									multiline
									rows={4}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12} sm={6} md={3}>
							<Select
								label="Type"
								control={control}
								name="notificationType"
								formControlProps={{ fullWidth: true, disabled: isLoading }}
								defaultValue="PRESET_UPDATE"
								onChange={() => {
									resetField('data.id', undefined);
								}}
							>
								<MenuItem value="PRESET_UPDATE">Preset</MenuItem>
								<MenuItem value="ARTIST_UPDATE">Artist</MenuItem>
								<MenuItem value="VERSION_UPDATE">New Version</MenuItem>
								<MenuItem value="EVENT">Event</MenuItem>
								<MenuItem value="COFFEE_BREAK">Coffee Break</MenuItem>
							</Select>
						</Grid>
						{notificationType === 'PRESET_UPDATE' && (
							<Grid item xs={12} sm={6} md={4}>
								<AutocompleteField
									disabled={isLoading}
									control={control}
									name="data.id"
									label={idLabel}
									rules={{ required: 'Please, select preset' }}
									formControlProps={{ fullWidth: true }}
									autocompleteProps={{
										options: presets?.presets ?? [],
										loading: isLoadingPresets,
										getOptionLabel: (option: Preset | string) =>
											typeof option === 'string'
												? option
												: `${option.id} - ${option.name}`,

										onInputChange: (event, value: string) => setIdQuery(value),
									}}
								/>
							</Grid>
						)}
						{notificationType === 'ARTIST_UPDATE' && (
							<Grid item xs={12} sm={6} md={4}>
								<AutocompleteField
									disabled={isLoading}
									control={control}
									name="data.id"
									label={idLabel}
									rules={{ required: 'Please, select artist' }}
									formControlProps={{ fullWidth: true }}
									autocompleteProps={{
										options: artists?.objects ?? [],
										loading: isLoadingArtists,
										getOptionLabel: (option: Artist | string) =>
											typeof option === 'string'
												? option
												: `${option.id} - ${option.fullName}`,
										onInputChange: (event, value: string) => setIdQuery(value),
									}}
								/>
							</Grid>
						)}

						<Grid container item xs={12} columnSpacing={4} rowSpacing={2}>
							<Grid
								item
								xs={12}
								sm="auto"
								md={3}
								display="flex"
								alignItems="center"
								justifyContent="center"
							>
								<Controller
									control={control}
									name="isToken"
									render={({ field: { onChange, ...field } }) => (
										<Stack direction="row" spacing={1} alignItems="center">
											<Typography>Topic</Typography>
											<Switch
												onChange={(event) => {
													resetField('topics', undefined);
													onChange(event);
												}}
												{...field}
												disabled={isLoading}
											/>
											<Typography>Token</Typography>
										</Stack>
									)}
								/>
							</Grid>
							{isToken ? (
								<Grid item xs={12} sm={6} md={4}>
									<TextField
										disabled={isLoading}
										fullWidth
										label="Token"
										inputProps={{
											...register('token', {
												shouldUnregister: true,
												validate: (value) => {
													const topics = getValues('topics');

													if (value) {
														if (topics) {
															return 'There cannot be both token and topics';
														}
													} else {
														if (!topics) {
															return 'Please, fill either a token or topics';
														}
													}

													return true;
												},
											}),
										}}
										error={Boolean(errors.token)}
										helperText={errors.token?.message}
									/>
								</Grid>
							) : (
								<Grid item xs={12} sm>
									<AutocompleteField
										control={control}
										name="topics"
										label="Topic"
										rules={{ required: 'Please, choose topics' }}
										disabled={isLoading}
										formControlProps={{ fullWidth: true }}
										autocompleteProps={{
											freeSolo: true,
											options: topics?.map((el) => el.topicName),
											loading: isLoadingTopics,
											multiple: true,
										}}
									/>
								</Grid>
							)}
						</Grid>
					</Grid>
				</Box>
			</DialogContent>
			<DialogActions>
				<Grid container columnSpacing={2} rowSpacing={1} ml={0} mb={1}>
					<Grid
						item
						xs={12}
						sm={6}
						md={3}
						lg={2}
						ml="auto"
						order={{ xs: 2, sm: 1 }}
					>
						<Button
							fullWidth
							size="large"
							onClick={onDialogClose}
							disabled={isLoading}
							color="inherit"
						>
							Cancel
						</Button>
					</Grid>
					<Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, sm: 2 }}>
						<LoadingButton
							variant="contained"
							fullWidth
							size="large"
							type="submit"
							loading={isLoading}
						>
							Create
						</LoadingButton>
					</Grid>
				</Grid>
			</DialogActions>
		</Dialog>
	);
}
