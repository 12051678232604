import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  PaperProps,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSelector } from 'react-redux';
import {
  DEFAULT_ERROR_MESSAGE,
  DEFAULT_SUCCESS_MESSAGE,
} from '../../constants';
import { VALIDATORS } from '../../constants/validators';
import useToast from '../../hooks/useToast';
import ApiService from '../../services/ApiService';
import { SubscriptionPlansService } from '../../services/SubscriptionPlansService';
import { StoreState, useAppDispatch } from '../../store';
import { setIsAddArtistDialogOpen } from '../../store/appSlice';
import { Artist, ISubscriptionPlanCreate } from '../../types';
import ImageUploadField from '../fields/ImageUploadField';
import Typography from '../material/Typography';

interface PaperPropsType extends PaperProps {
  component: 'form';
}

export interface ArtistFormProps {
  name: string;
  email: string;
  instagramURL: string;
  avatar: File | string;
  position: number;
  hasArtistSubscriptionPlan?: boolean;
  artistSubscriptionPlanDto?: any;
  price?: number;
  isSubscriptionActive?: boolean;
}

interface DialogProps {
  refetch: () => any;
  onClose: () => void;
  artist?: Artist;
  defaultValues?: Partial<ArtistFormProps>;
}

export default function EditArtistDialog({
  refetch,
  onClose,
  artist,
  defaultValues,
}: DialogProps) {
  const toast = useToast();
  const isUpdate = defaultValues !== undefined;
  const isOpen = useSelector(
    (state: StoreState) => state.app.isAddArtistDialogOpen
  );
  const dispatch = useAppDispatch();
  const saveArtistMutation = useMutation(
    (data: ArtistFormProps) =>
      isUpdate
        ? ApiService.updateArtist(artist?.id ?? 0, data)
        : ApiService.createArtist(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        refetch();
        onDialogClose();
      },
    }
  );

  const [isCreatingSubscriptionPlan, setIsCreatingSubscriptionPlan] =
    useState(false);

  const hasArtistSubscriptionPlan = defaultValues?.hasArtistSubscriptionPlan;

  const isSubscriptionFormVisible =
    isCreatingSubscriptionPlan || hasArtistSubscriptionPlan;

  const saveSubscriptionMutation = useMutation(
    (data: ISubscriptionPlanCreate) =>
      hasArtistSubscriptionPlan
        ? SubscriptionPlansService.updateSubscriptionPlan(
            artist?.artistSubscriptionPlanDto?.id ?? 0,
            data
          )
        : SubscriptionPlansService.createSubscriptionPlan(data),
    {
      onError(error, variables, context) {
        toast(DEFAULT_ERROR_MESSAGE);
      },
      onSuccess(data, variables, context) {
        toast(DEFAULT_SUCCESS_MESSAGE, 'success');
        refetch();
        onDialogClose();
      },
    }
  );

  const { isLoading: isSavingArtist } = saveArtistMutation;

  const { isLoading: isSavingSubscriptionPlan } = saveSubscriptionMutation;

  const isLoading = isSavingArtist || isSavingSubscriptionPlan;

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    setValue,
  } = useForm<ArtistFormProps>();

  const onDialogClose = () => {
    if (isLoading) {
      return;
    }

    dispatch(setIsAddArtistDialogOpen(false));
    onClose();
    reset({
      avatar: undefined,
      email: undefined,
      instagramURL: undefined,
      name: undefined,
    });
  };

  const onSubmit = async ({
    hasArtistSubscriptionPlan,
    artistSubscriptionPlanDto,
    ...data
  }: ArtistFormProps) => {
    if (isLoading) {
      return;
    }

    if (isSubscriptionFormVisible) {
      const subscriptionPlanData: ISubscriptionPlanCreate = {
        ...artistSubscriptionPlanDto,
        price: data.price,
        isActive: data.isSubscriptionActive ?? true,
        artistId: artist?.id ?? 0,
        currency: 'KZT',
        period: 'month',
        name: 'Месячная подписка на ' + artist?.fullName,
      };

      await saveSubscriptionMutation.mutateAsync(subscriptionPlanData);
    }

    // const data: ArtistFormProps = dataAny;

    // const mutationData: ArtistCreate = {
    // 	userRegistrationDto: {
    // 		email: data.email,
    // 		fullName: data.name,
    // 		password: '123456',
    // 		phoneNumber: '+77777777777',
    // 	},
    // 	avatar: data.avatar,
    // };

    saveArtistMutation.mutate(data);
  };

  const DialogPaperProps: PaperPropsType = {
    component: 'form',
    onSubmit: handleSubmit(onSubmit),
  };

  useEffect(() => {
    if (!defaultValues || !isOpen) {
      reset();
      return;
    }

    setIsCreatingSubscriptionPlan(false);
    reset(defaultValues);
  }, [defaultValues, isOpen]);

  return (
    <Dialog
      open={isOpen}
      onClose={onDialogClose}
      fullWidth
      maxWidth="md"
      PaperProps={DialogPaperProps}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {isUpdate ? 'Edit Artist' : 'Add Artist'}
        <IconButton edge="end" onClick={onDialogClose} disabled={isLoading}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box pt={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4} md={3} margin="auto" maxWidth={200}>
              <ImageUploadField
                control={control}
                name="avatar"
                label="Avatar"
                formControlProps={{ disabled: isLoading }}
                rules={{ required: 'Please, select avatar' }}
              />
            </Grid>
            <Grid item container xs={12} sm={8} md={9} spacing={2}>
              <Grid item xs={12}>
                <TextField
                  disabled={isLoading}
                  fullWidth
                  label="Name"
                  inputProps={{
                    ...register('name', { required: 'Please, enter name' }),
                  }}
                  error={Boolean(errors.name)}
                  helperText={errors.name?.message}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={isLoading}
                  fullWidth
                  type="email"
                  label="Email"
                  inputProps={{
                    ...register('email', { ...VALIDATORS.EMAIL }),
                  }}
                  error={Boolean(errors.email)}
                  helperText={errors.email?.message}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <TextField
                  disabled={isLoading}
                  fullWidth
                  label="Instagram URL"
                  inputProps={{
                    ...register('instagramURL'),
                  }}
                  error={Boolean(errors.instagramURL)}
                  helperText={errors.instagramURL?.message}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  disabled={isLoading}
                  fullWidth
                  type="number"
                  label="Position"
                  inputProps={{
                    ...register('position', { valueAsNumber: true }),
                  }}
                  error={Boolean(errors.position)}
                  helperText={errors.position?.message}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6" mt={2}>
                Subscription Plan
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Grid container columnSpacing={2} rowSpacing={1} ml={0} mb={1}>
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            lg={2}
            ml="auto"
            order={{ xs: 2, sm: 1 }}
          >
            <Button
              fullWidth
              size="large"
              onClick={onDialogClose}
              disabled={isLoading}
              color="inherit"
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} order={{ xs: 1, sm: 2 }}>
            <LoadingButton
              variant="contained"
              fullWidth
              size="large"
              type="submit"
              loading={isLoading}
            >
              {isUpdate ? 'Save' : 'Create'}
            </LoadingButton>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
