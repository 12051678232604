import { Box, Grid, styled, useMediaQuery, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';
import AddNotificationDialog from '../components/forms/AddNotificationDialog';
import SideMenu from '../components/SideMenu';
import { SIDEBAR_WIDTH } from '../constants';

const LayoutContainer = styled(Box)(({ theme }) => ({
	display: 'flex',
	minHeight: '100vh',
	maxHeight: '100vh',
	height: '100vh',
	width: '100vw',

	'.page-body': {
		display: 'flex',
		flexDirection: 'column',
		flex: '1 1 auto',
	},
}));

export default function MainLayout() {
	const theme = useTheme();
	const matchesMdDown = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<LayoutContainer>
			<Grid container>
				<Grid item minWidth={matchesMdDown ? 0 : SIDEBAR_WIDTH}>
					<SideMenu />
				</Grid>

				<Grid item xs className="page-body">
					<Outlet />
				</Grid>
			</Grid>
		</LayoutContainer>
	);
}
