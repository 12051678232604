import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import { forwardRef, useState } from 'react';

const PasswordField = forwardRef((props: TextFieldProps, ref) => {
	const [isVisible, setIsVisible] = useState(false);

	const onClick = () => {
		setIsVisible((prev) => !prev);
	};

	return (
		<TextField
			type={isVisible ? 'text' : 'password'}
			{...props}
			InputProps={{
				endAdornment: (
					<IconButton
						size="large"
						edge="end"
						onClick={onClick}
						disabled={props.disabled}
					>
						{isVisible ? <VisibilityOff /> : <Visibility />}
					</IconButton>
				),
			}}
		/>
	);
});

export default PasswordField;
